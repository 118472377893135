<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="Backupmonitoring" style="width:100vw">
  <div id="e40d1a21">
    备份监控
  </div>
  <el-table id="a5258891" :data="tbd_a5258891" stripe="" border="">
    <el-table-column prop="c255cf2b" label="目标">
    </el-table-column>
    <el-table-column prop="a73452cc" label="备份">
    </el-table-column>
  </el-table>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
export default{
  data(){
    return{
    tbd_a5258891:[]
      //在此注释下方添加自定义变量
    }
  },
  mounted(){
  },
  computed:{
  },
  watch:{
  },
  methods:{
    //在此注释下方添加自定义函数或事件
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#e40d1a21{
  padding-top:100px;
}
#e40d1a21{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#a5258891{
  width:1323px;
  margin-top:30px;
  margin-left:163px;
  padding-bottom:1303px;
}
#a5258891{
  display:inline-block;
  vertical-align: middle;
}
/*在此注释下方添加自定义样式*/
</style>
